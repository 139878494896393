<template>
    <div class="contact-wrapper">
        <div class="contact-info">
            <div>
                <h4>Send Us An Email</h4>
                <ul>
                     
                    <li>We treat all e-mails as priority and will provide a response as quickly as quickly as possible</li>
                    <li>Send us a direct email on <a href="mailto:businessconcierge@providusbank.com" target="_blank">businessconcierge@providusbank.com </a></li>
                    <li>To report a fraud or suspicious transaction 
                        on your account, please send an e-mail to <a href="mailto:frauddesk@providusbank.com" target="_blank">frauddesk@providusbank.com</a></li>
                </ul>
            </div>
            <div>
                <h4>
                    Connect With Us On Social Media
                </h4>
                <ul>
                    <li>Our genuine accounts are available for messaging 24/7. 
                        Please be wary of imitations bearing some sort of semblance with our original brand in name or logo.</li>
                </ul>
            </div>
            <div>
                <h4>Connect Us By Phone</h4>
                <ul>
                    <li>We are available to take your calls 24/7 
                        and have provided an option for you to request a call back on our busy days.</li>
                </ul>
            </div>
            <div>
                <h4>HQ Address</h4>
               <p>114, Adeola Odeku, Eletu Ogabi Street,Victoria Island, Corporate Office,Lagos state.</p>
            </div>
        </div>
        <div class="contact-img">
            <img src="/images/contact.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name: 'ContactView'
}
</script>
<style>



</style>