<template>
    <div class="main-wrapper main-wrapper__center">
        
        <p>Log Complaints</p>
        <img src="/images/rule.svg" alt="border" class="complaints-border">
        <div class="complaints-area">
            <div class="flex">
                <p style="font-size: 12px;">Account Number</p>
                <input type="text" name="" id="" class="complaints-input input" placeholder="Account Number" v-model="formData.account" readonly>
            </div>
           
            <div class="flex">
                <p style="font-size: 12px;">Complaint Type</p>
                <select name="" id="" class="select-input input" v-model="formData.type">
                <option value="" disabled selected hidden>Complaints Type</option>
                <option value="1">Blocked Funds</option>
            </select>
            <p style="color:red; font-size: 10px;">{{ errorMessage }}</p>
            </div>
            <div class="flex" v-if="formData.type == 1">
                <p style="font-size: 12px;">Error Type</p>
                <select name="" id="" class="select-input input" v-model="formData.is_91">
                <option value="" disabled selected hidden>Error Type</option>
                <option value="1">Error 91</option>
                <option value="0">Others</option>
            </select>
            <p style="color:red; font-size: 10px;">{{ errorValueMessage }}</p>
            </div>
            
            <div class="flex" v-show="formData.is_91 == 1">
                <p style="font-size: 12px;">Transaction Date</p>
                <input type="date" name="" id="" class="date-input input" placeholder="Transaction Date" v-model="formData.txn_date">
                <p style="color:red; font-size: 10px;">{{ dateErrorMessage }}</p>
            </div>
            <div class="flex" v-show="formData.is_91 == 1">
                <p style="font-size: 12px;">Transaction Amount</p>
                <input type="text" name="" id="" class="complaints-input input" placeholder="Amount" v-model="formData.amount">
                <p style="color:red; font-size: 10px;">{{ amountErrorMessage }}</p>
            </div>
            <div class="flex" v-show="formData.is_91 == 0">
                <p style="font-size: 12px;">Upload Transaction Receipt</p>
                <input type="file" ref="file" name="" id="" class="file-input" placeholder="Amount" >
                <!-- <p style="color:red; font-size: 10px;">{{ amountErrorMessage }}</p> -->
            </div>
            
        </div>
        <!-- <img src="/images/rule.svg" alt="border" class="complaints-border"> -->
        <button class="log-btn-submit" style="margin-top: 1.5rem;" @click="logComplaints" v-show="formData.is_91==1">Submit Complaint</button>
        <button class="log-btn-submit" style="margin-top: 1.5rem;" @click="logComplaints_others" v-show="formData.is_91==0">Submit Complaint</button>
    </div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue';
import { makeGetRequest, makePostRequest } from '@/request';
import { toast } from 'vue3-toastify';
import { useUserStore } from '@/stores'
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

const { handleSubmit, validate, resetForm } = useForm();
const { value, errorMessage } = useField('type', yup.string().required());
const { value:errorValue, errorMessage:errorValueMessage } = useField('is_91', yup.string().required());
const { value: amountValue, errorMessage:amountErrorMessage } = useField('amount', yup.string().required().matches(/^\d+(\.\d{2})?$/, 'Invalid money format. Example: 2000.00'))
const { value: dateValue, errorMessage:dateErrorMessage } = useField('date', yup.string().required())
const swal = inject('$swal')
const user = useUserStore()
const file = ref(null)
const formData = ref({
    account: user.accountNumber,
    txn_date: dateValue,
    amount: amountValue,
    type: value,
    is_91: errorValue
})

const complaintType = ref([])
const preLoader = () => { 
      swal.fire({title: "", 
      customClass: {
          htmlContainer: 'custom-swal-popup'// Add your custom class here
      },
      html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="./images/loading.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});
}
const getComplaintsType = () => {

    preLoader()
    
  var request = {
    what: 'GetCustomerComplaintsType'
  }
  makeGetRequest(request)
    .then((res) => {
      if (res.success) {
        complaintType.value = res.data
        swal.close()
      }
      else {
        console.log(res.msg)
      }
    })
    .catch((error) => {
        swal.close()
      toast.error(error)
    })
}
const logComplaints = handleSubmit(async() => {
    const isValid = await validate();
   if (isValid) {

   
    preLoader()
    
    
    
    var request = {
        what: 'LogComplaints',
        data: formData.value
    };
    if (formData.value.is_91 == 1){
        formData.value.is_91 = true
    } else {
        formData.value.is_91 = false
    }
    makePostRequest(request)
    .then(res => {
        if (res.status == true){
            toast.success(res.msg)
            resetForm()
            getComplaintsType()
            swal.close()
        }
        else{
            toast.error(res.msg)
            swal.close()
        }
    })
    .catch(error => {
        swal.close()
        toast.error(error)
    });
}  
})
const logComplaints_others = () => {
    preLoader()
    if (formData.value.is_91 == 1){
        formData.value.is_91 = true
    } else {
        formData.value.is_91 = false
    }
        const data = new FormData();
        data.append('receipt', file.value.files[0])
        data.append('account', formData.value.account)
        data.append('is_91', formData.value.is_91)
    var request = {
        what: 'LogComplaints',
        data: data
    };
    makePostRequest(request)
    .then(res => {
        if (res.status == true){
            toast.success(res.msg)
            resetForm()
            getComplaintsType()
            swal.close()
        }
        else{
            toast.error(res.msg)
            swal.close()
        }
    })
    .catch(error => {
        swal.close()
        toast.error(error)
    });
    
}
onMounted(() => {
    // getComplaintsType()
})
</script>
<script>
export default {
    name: 'LogComplaint'
}
</script>
<style scoped>
.flex{
    display: flex;
    flex-direction: column;
}
.main-wrapper__center{
    /* align-items: center !important;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 60%;
}
     */
     
}
</style>