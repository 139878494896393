<template>
    <div class="track-complaints">
        <div class="complaint-status">
            <h2>Want to know the status of your complaint?</h2>
            <div>
                <input type="text" name="" id="">
                <button>Track</button>
            </div>
        </div>
        <div class="complaint-table">
            <v-table class="table">
                <thead>
                  <tr>
                    <th class="text-left">
                      Transaction ID
                    </th>
                    <th class="text-left">
                      Complaint Type
                    </th>
                    <th class="text-left">
                        Account Number
                      </th>
                      <th class="text-left">
                        Transaction Amount
                      </th>
                      <th class="text-left">
                        Transaction Date
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                  >
                    <td>TRXD33IHE84</td>
                    <td>ATM/POS</td>
                    <td>0022331211</td>
                    <td>2,000.00</td>
                    <td>18-06-2023</td>
                    <td><span class="status-resolved">Resolved</span></td>
                  </tr>
                  <tr
                  >
                    <td>TRXD33IHE84</td>
                    <td>ATM/POS</td>
                    <td>0022331211</td>
                    <td>2,000.00</td>
                    <td>18-06-2023</td>
                    <td><span class="status-pending">Pending</span></td>
                  </tr>
                </tbody>
            </v-table>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TrackComplaint'
}
</script>
<style scoped>
  
</style>