import { defineStore } from 'pinia'

// Helpers
const helpers = {
    getCurrentYear() {
        return new Date().getFullYear()
    }
}

export const useUserStore = defineStore('UserStore',  {
    state: () => ({
        isLoggedIn: '',
        username:'',
        token: '',
        role: '',
        accountNumber:'',
        app: {
            name: 'Complaint Portal',
            version: process.env.PACKAGE_VERSION,
            env: process.env.VUE_APP_ENVIRONMENT,
            copyright: helpers.getCurrentYear()
        },
        code: '',
        redirecturi: 'https://192.168.52.20',
        clientId: '8b5c92c2-5e53-4436-920c-b84957975076',
        tenantId: '71a68709-ebf9-4736-a0d6-b789e10324dd'
        
    }),
    actions: {
        setCode(data){
            this.code = data
        },
        setToken(data){
            this.token = data
        },
        setUserName(data){
            this.username = data
        },
        setLogin(data){
            this.isLoggedIn = data
        },
        setRole(data){
            this.role = data
        },
        setAccountNumber(data){
            this.accountNumber = data
        }
    },
    persist: true
})