import  { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import "./assets/styles.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faMessage, faSearch, faPhone, faPowerOff, faChevronDown, faUser, faTimes, faChevronRight, faChevronLeft, faTag} from '@fortawesome/free-solid-svg-icons'
library.add(faTwitter, faFacebook, faInstagram, faYoutube, faMessage, faSearch, faPhone, faPowerOff, faChevronDown, faUser, faTimes, faChevronLeft, faChevronRight, faTag)


import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { msalInstance } from "./AuthConfig";
import {useUserStore} from './stores'
import { EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/navigationClient";
import { msalPlugin } from "./plugins/msalPlugin";
import axios from 'axios';
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue3Toasity, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia';

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const vuetify = createVuetify({
  components,
  directives,
})
// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

router.beforeEach((to, from, next) => {
  const user = useUserStore()
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = user.isLoggedIn

  if ((!isPublic && !loggedIn)) {
    return next({
        path: "/",
    });
  }
  if (loggedIn && onlyWhenLoggedOut) {
    if (isPublic && user.role === 'System Administrator') {
        return next("/users");
    }
    if (isPublic && user.role === 'Customer') {
      return next("/log-complaint");
    }
  if (isPublic && user.role === 'Bank Representative') {
    return next("/complaint");
  }
    return next("/");
  }
  if (to.meta.requiresAuth) {
      if (user.isLoggedIn) {
        if (to.path === '/') {
          if (user.role === 'System Administrator'){
            next('/users')
          }
          if (user.role === 'Customer'){
            next('/log-complaint')
          }
          if (user.role === 'Bank Representative'){
            next('/complaint')
          }
        }
          next()
          return
      }
      next('/')
  } else {
      next()
  }
})
const app = createApp(App)
app.use(msalPlugin, msalInstance);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueAxios, axios)
app.use(Vue3Toasity, {
  autoClose: 3000,
  position: toast.POSITION.TOP_RIGHT
});
app.use(pinia)
app.use(router).use(vuetify).use(VueSweetalert2).mount('#app')
