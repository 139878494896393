<template>
    <header class="header-container">
        <img src="/images/icon-menu.svg" alt="" @click="showMenu">
        <p style="flex: 1;font-size: 14px;">Self-Service Complaints Portal</p>
        <p style="margin-right: 20px;font-size: 14px;
    ">Welcome, Oluwakemi O.</p>
        <button class="log-out-btn" @click="exitPortal" v-if="user.role== 'Customer'">Log Out</button>
        <button class="log-out-btn" v-else @click="$router.push('/')">Log Out</button>
        <button class="log-out-btn-sm">
            Logout
            <!-- <i><font-awesome-icon icon="user"  /></i>
            <i><font-awesome-icon icon="chevron-down"  /></i> -->
        </button>
        <!-- <div class="logout-sm">
            <div>
                <p>Kemi Omoyeni</p>
            <button class="" @click="$router.push('/')">Log Out</button>
            </div>
            
        </div> -->
    </header>
    <div class="nav-container-sm" v-if="show">
        <div class="nav-container-sm-header">{{ role }}
            <img src="/images/providus-2.png" alt="logo">
            <i @click="show=false"><font-awesome-icon icon="fa-solid fa-times" size="lg"/></i>
        </div>
        {{ role }}
        <ul v-for="(node, index) in nodes" :key="`node-${index}`">
            <router-link v-if="shouldDisplay(node, role)" :to="node.to || '#'" class="nav-inactive"
                :active-class="(node.to && node.to !== '#' && node.to !== '/') ? 'nav-active' : 'nav-inactive'">
                <li>
                    <!-- <i  class="contact-icon"><font-awesome-icon :icon="`fa-solid ${node.icon}`" size="lg" /></i> -->
                    <i ><font-awesome-icon :icon="`fa-solid ${node.icon}`" size="lg" /></i>{{ node.name}}
                </li>
            </router-link>
        </ul>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { useMsal } from '../composition-api/useMsal';
import menuList from '@/data/menu'
import { useUserStore } from '@/stores'
const { instance } = useMsal();
const show = ref(false)
const user = useUserStore()
const role = user.role
const nodes = menuList.main

const showMenu = () =>{
    show.value = !show.value
}

const shouldDisplay = (node, role) => {
      // Check if the userRole is included in the node's role array
      return Array.isArray(node.role) ? node.role.includes(role.value) : node.role === role.value;
    
}
const exitPortal = () => {
  instance.logoutRedirect();
  
  user.isLoggedIn = false
}
</script>
<script>
export default {
    name: 'HeaderLayout',
}
</script>