import { useUserStore } from "./stores"
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

export const makeGetRequest = (request) => {
    const request_urls = {
        'GetComplaintsType': 'control/complaints/',
        'GetCustomerComplaintsType': 'complaint/types/',
        'GetBankRep': 'control/bank-representatives/'
        
    }    
    const user = useUserStore()
    const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    } else {
        request_url = request_urls[request.what]
    }
    
    //console.log('%cSending get request to:' + request_url, 'color:#00ff00;font-size:14px;background:#000;', 'color:#ff0000;font-size:14px;background:#000;')
    
    return new Promise((resolve, reject) => { 
    axios.get(request_url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {        
            if (response.data.status) {
                var res = {
                    data: response.data.data,
                    type: request.what,
                    success: true
                }                
                resolve(res)
            } else {
                reject(response.data)
            }
        })
        .catch((err) => {
            if(err.response) reject(err.response.data.msg)
            else reject("Network Error. Reload the page or check your internet connection")
        })
    })
}

export const makePostRequest = (request)  => {
    const request_urls = {
        'CustomerAuthentication': 'auth/proceed/',
        'BankRepAuthentication': 'auth/ad-login/',
        'LogComplaints': 'complaint/new/blocked-funds/',
        'CreateComplaintsType': 'control/complaints/',
        'CreateBankRep': 'control/bank-representatives/'
    }
        const user = useUserStore()
        const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    }else{
        request_url = request_urls[request.what]
    }

    var data = (request.data !== undefined) ? request.data : {}
    var config;
    if(request_url !== 'auth/proceed/' && request_url !== 'auth/ad-login/'){ // do not set access token if request is pointing to 'authenticate'
        config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    }   
    return new Promise((resolve, reject) => {
    axios.post(request_url, data, config)
            .then((response) => {
                var res;
                if (response.status) {
                    res = {
                        msg: response.data.msg,
                        type: request.what,
                        status: true,
                        data: response.data.data
                    }
                    resolve(res)
                } else {
                    reject(response.data)
                }
            })
            .catch((err) => {
                if(err.response) reject(err.response.data.msg)
                else reject("Network Error. Reload the page or check your internet connection")
            })
    })
}

export const makePatchRequest = (request)  => {

    const request_urls = {
    }
    const user = useUserStore()
        const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    }else{
        request_url = request_urls[request.what]
    }

    var data = (request.data !== undefined) ? request.data : {}
    var config = {
        headers: {
            'Authorization': 'Bearer ' + token 
        }
    }

    //console.log('%cSending patch request to: ' + request_url, 'color:#00ff00;font-size:14px;background:#000;')
    return new Promise((resolve, reject) => {
    axios.patch(request_url, data, config)
            .then((response) => {
                if (response.data.status) {                    
                    var res = {
                        msg: response.data.msg,
                        type: request.what,
                        status: true,
                    }
                    resolve(res)
                } else {
                    reject(response.data)
                }
            })
            .catch((err) => {
                if(err.response) reject(err.response.data.msg)
                else reject("Network Error. Reload the page or check your internet connection")
            })
    })
}

export const makePutRequest = (request)  => {

    const request_urls = {
        'EditComplaintsType': 'control/complaint/'  + request.id + '/'
    }
    const user = useUserStore()
    const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    }else{
        request_url = request_urls[request.what]
    }

    var data = (request.data !== undefined) ? request.data : {}
    var config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    return new Promise((resolve, reject) => {
    axios.put(request_url, data, config)
            .then((response) => {
                if (response.data.status) {                    
                    var res = {
                        msg: response.data.msg,
                        type: request.what,
                        status: true,
                    }
                    resolve(res)
                } else {
                    reject(response.data)
                }
            })
            .catch((err) => {
                if(err.response) reject(err.response.data.msg)
                else reject("Network Error. Reload the page or check your internet connection")
            })
    })
}