<template>
  <div>
    <main>
      <div class="container customer-container">
        <img src="/images/providus-1.png" alt="" />
        <div class="input-wrapper">
          <div class="input-text">
            <h4>Customer</h4>
            <p>Provide your valid credentials to continue</p>
          </div>

          <div class="input-box__wrapper" style="">
            <input
              type="text"
              placeholder="Account Number"
              v-model="formData.account_number"
            />
            <p
              style="color: red; font-size: 10px; margin-top: -10px"
              v-if="accountErrorMessage != ''"
            >
              {{ accountErrorMessage }}
            </p>
            <input
              type="text"
              placeholder="Phone Number"
              v-model="formData.phone"
            />
            <p
              style="color: red; font-size: 10px; margin-top: -10px"
              v-if="errorMessage != ''"
            >
              {{ errorMessage }}
            </p>
            <input
              type="button"
              id="signIn-btn"
              value="Sign In"
              class="active"
              @click="testSign"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import { ref, inject } from "vue";
// import { makePostRequest } from '@/request';
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import { useUserStore } from "@/stores";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";

const { handleSubmit, validate, resetForm } = useForm();
const { value, errorMessage } = useField(
  "phone",
  yup
    .string()
    .required()
    .matches(
      /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907|909|908|911|912|913|914|915|916|917|919)([0-9]{7})$/,
      "Phone Number must be valid"
    )
);
const { value: accountNumberValue, errorMessage: accountErrorMessage } =
  useField(
    "account_number",
    yup
      .string()
      .required()
      .matches(/^\d{10}$/, "Account number must be exactly 10 digits")
  );
const swal = inject("$swal");

const router = useRouter();
const formData = ref({
  phone: value,
  account_number: accountNumberValue,
});
// const clearForm = () => {
//     formData.value.phone = ''
//     formData.value.account_number = ''
// }
// const preLoader = () => {
//       swal.fire({title: "",
//       customClass: {
//           htmlContainer: 'custom-swal-popup'// Add your custom class here
//       },
//       html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="./images/loading.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});
// }
// const SignIn = handleSubmit(async() => {
// console.log('I start')
//     const isValid = await validate();
//     if (isValid){
//         console.log("I'm valid")
//     preLoader()
//   const user = useUserStore()
//   var request = {
//     what: "CustomerAuthentication",
//     data: formData.value
//   };
//   console.log('about to make request')
//   const result = makePostRequest(request)
//   console.log(result, 'I am the result of the post request')
//   console.log('Successfully made request')
//     .then(res => {
//         console.log('I have seen request')
//         console.log(res, 'I am the response')
//       if (res.status == true) {
//         console.log('Status is true')
//         user.setLogin(true)
//         user.setToken(res.data.token)
//         user.setAccountNumber(res.data.account_number)
//         user.setRole(res.data.role)
//         console.log("I've set data in the LS")
//         toast.success(res.msg);
//         resetForm()
//         swal('Login Successful', res.msg, 'success')
//         router.push('/log-complaint')
//         console.log("I Move to the complaints page")
//       }
//       else {
//         toast.success(res.msg);
//       }
//     })
//     .catch(error => {
//         swal.close()
//         toast.error(error)
//     });
// }
// })
// const testSign = handleSubmit( async() =>{
//     const user = useUserStore()
//     const isValid = await validate();
//     if (isValid){
//     const requestOptions = {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(formData.value) //pass it into the post api
//     };
//         fetch("http://127.0.0.1:8000/auth/proceed/", requestOptions)
//             .then(response => {
//                 return response.json();
//               })
//               .then(data => {
//                     console.log(data)
//                     console.log(data.data)
//                     console.log(data.data.token)
//                     user.setLogin(true)
//                     user.setToken(data.data.token)
//                     user.setAccountNumber(data.data.account_number)
//                     user.setRole(data.data.role)
//                     toast.success(data.msg);
//                     resetForm()
//                     swal('Login Successful', data.msg, 'success')
//                     router.push('/log-complaint')
//               })
//               .catch(err => toast.error(err, 'error'));
//             }

// })
const testSign = handleSubmit(async () => {
  const user = useUserStore();
  const isValid = await validate();
  if (isValid) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData.value), // Assuming formData is defined somewhere
    };

    try {
      const response = await fetch(
        "http://127.0.0.1:8000/auth/proceed/",
        requestOptions
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        console.log(data.data);
        console.log(data.data.token);
        toast.success(data.msg);
        swal({
          title: "Login Successful",
          text: data.msg,
          icon: "success",
        }).then((result) => {
          if (result.value) {
            user.setLogin(true);
            console.log("After setting login");
            user.setToken(data.data.token);
            console.log("After setting token");
            console.log(user.token);
            user.setAccountNumber(data.data.account_number);
            console.log("After setting account number");
            console.log(user.accountNumber);
            user.setRole(data.data.role);
            console.log("After success role");
            console.log(user.role);
            toast.success(data.msg);
            console.log("After success toast");
            resetForm();
            console.log("After resetting form");
            console.log("After swal");
            router.push("/log-complaint");
            console.log("After router");
          }
        });

        // swal('Login Successful', data.msg, 'success');
      } else {
        throw new Error(`HTTP Error: ${response.status}`);
      }
    } catch (err) {
      toast.error(err.message, "error");
    }
  }
});
</script>
<script>
export default {
  name: "CustomerLogin",
};
</script>
<style></style>
