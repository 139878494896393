<template>
    <div>
        <main>
            <div class="container">
                <img src="/images/providus-1.png" alt="">
                <div class="button-wrapper">
                    <p>I am a?</p>
                    <div class="container-button">
                        <button class="active" @click="$router.push('/customer-login')">Customer</button>
                        <button class="inactive" @click="loginRedirect">Bank Representative</button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { onMounted, inject} from 'vue'
import { useUserStore } from '../stores'
import { makePostRequest } from '../request'
import { toast } from 'vue3-toastify';
const router = useRouter()
const swal = inject('$swal')

const authenticateUsers = (code, uri) => {
    const user = useUserStore()
    var request = {
        what: "BankRepAuthentication",
        data: { code: code, redirect_uri: uri }
    };
    makePostRequest(request)
        .then(res => {
            if (res.status == true) {
                toast.success(res.msg);
                swal({
                    title: 'Login Successful',
                    text: res.msg,
                    icon: 'success',
                }).then((result) => {
                    if (result.value) {
                        user.setLogin(true)
                    user.setToken(res.data.token)
                    user.setAccountNumber(res.data.account)
                    user.setRole(res.data.role)     
                        if(user.role == 'System Administrator'){
                            router.push('/users')
                    }
                    else{
                        router.push('/complaints')
                    }
                }
                })
                
                
            }
            else {
                toast.success(res.msg);
            }

        })
        .catch(error => {
            swal.close()
            // this.is401(error.response.status);
            toast.error(error);
        });
};
const loginRedirect = async () => {
    const user = useUserStore()
    // instance.loginRedirect(loginRequest);
    window.location.href = "https://login.microsoftonline.com/" + user.tenantId + "/oauth2/v2.0/authorize?client_id=" + user.clientId + "&response_type=code&redirect_uri=" + user.redirecturi + "&scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&state=12345"
}
onMounted(() => {
    const searchParams = window.location.href;
    const startIndex = searchParams.indexOf('code=') + 5;
    const endIndex = searchParams.indexOf('&', startIndex);
    const code = searchParams.substring(startIndex, endIndex);

    if (code.length > 24) {
        console.log('Authorization Code:', code);
        const user = useUserStore()
        user.setCode(code);

        authenticateUsers(user.code, user.redirecturi)


    } else {
        console.error('Authorization Code not found in the URL.');
    }
})
</script>
<style>

</style>