<template>
    <nav>
        <div class="nav-container">
            <img src="/images/providus-2.png" alt="" @click="$router.push('/')">
            <ul v-for="(node, index) in nodes" :key="`node-${index}`">
                <router-link v-if="!node.heading && !node.sub && shouldDisplay(node, role)" :to="node.to || '#'" class="nav-inactive"
                    :active-class="(node.to && node.to !== '#' && node.to !== '/') ? 'nav-active' : 'nav-inactive'">
                    <li>
                        <i v-if="node.icon.includes('phone')" class="contact-icon"><font-awesome-icon :icon="`fa-solid ${node.icon}`" size="lg" /></i>
                        <i v-if="!node.icon.includes('phone')"><font-awesome-icon :icon="`fa-solid ${node.icon}`" size="lg" /></i>{{ node.name}}
                    </li>
                </router-link>
            </ul>
        </div>
    </nav>
</template>
<script setup>
// import { ref } from 'vue'
import menuList from '@/data/menu'
import { useUserStore } from '@/stores'


// const show = ref(false)
const user = useUserStore()
const role = user.role
const nodes = menuList.main

const shouldDisplay = (node, role) => {
    console.log(role, node, 'I work')
      // Check if the userRole is included in the node's role array
      return Array.isArray(node.role) ? node.role.includes(role) : node.role === role;
    
}
</script>
<script>

export default {
    name: 'SideLayout',
}
</script>