import {createRouter, createWebHistory} from "vue-router";
import CustomerLoginPage from "../views/CustomerLoginPage.vue"
import LandingPage from "../views/LandingPage.vue"
import LogComplaint from "../views/LogComplaint.vue"
import TrackComplaint from "../views/TrackComplaint.vue"
import ContactViews from "../views/ContactViews.vue"
import StaffComplaints from "../views/Complaints.vue"
import BaseLayout from "../components/Base.vue"
import StaffLayout from "../components/StaffBase.vue"
import UserPage from "../views/User.vue"
import ComplaintType from "../views/ComplaintType.vue"
const routes =[
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
        meta:{
          public: true, // Allow access to even if not logged in
          onlyWhenLoggedOut: true
        }
    },
    {
        path: '/customer-login',
        name: 'CustomerLogin',
        component: CustomerLoginPage,
        meta:{
          public: true, // Allow access to even if not logged in
          onlyWhenLoggedOut: true
        }
    },
    {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/log-complaint',
            name: 'Log Complaint',
            component: LogComplaint,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/track-complaint',
            name: 'Track Complaint',
            component: TrackComplaint,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/contact',
            name: 'Contact',
            component: ContactViews,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: StaffLayout,
        children: [
          {
            path: '/complaints',
            name: 'Complaints',
            component: StaffComplaints,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/users',
            name: 'Users',
            component: UserPage,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/types',
            name: 'Complaints Types',
            component: ComplaintType,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },

]

const routers = createRouter({
    history: createWebHistory(),
    routes
})

export default routers