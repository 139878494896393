<template>
    <footer>
        <div class="footer-container">
        <div>
            <p>&copy; Copyright 2023. ProvidusBank</p>
        </div>
        <div class="footer-policy">
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
        </div>
        <div class="footer-brands">
            <font-awesome-icon icon="fa-brands fa-facebook" size="lg"/>
            <font-awesome-icon icon="fa-brands fa-twitter" size="lg" />
            <font-awesome-icon icon="fa-brands fa-instagram" size="lg"/>
            <font-awesome-icon icon="fa-brands fa-youtube" size="lg"/>
        </div>
    </div>
    </footer>
</template>
<script>
export default {
    name: 'FooterLayout'
}
</script>