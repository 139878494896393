<template>
    <div class="main-wrapper">
        
        <div class="search-bar">
            <input type="search" name="" id="" placeholder="Search User by Email" v-model="search" @change="getBankRep">
            <button @click="dialog=true">Create User</button>
        </div>
        <div class="complaint-table">
            <v-table class="table">
                <thead>
                  <tr>
                    <th class="text-center">
                        S/N
                      </th>
                      <th class="text-center">
                        Name
                      </th>
                    <th class="text-center">
                      Email
                    </th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in users" :key="index">
                  <td class="text-center">{{index + 1}}</td>
                  <td class="text-center">{{user.name ?  user.name: 'N/A'}}</td>
                  <td class="text-center">{{user.email}}</td>
                  <td class="text-center"><button class="btn-submit status-pending" @click="handleDelete(user.id)">Delete</button></td>
                  </tr>
                </tbody>
            </v-table>
        </div>
        <div class="pagination">
          <!-- <v-pagination :length="6"></v-pagination> -->
            <div>
              <span>Page</span> 
              <span>1</span> 
              <span>of</span> 
              <span>10</span> <span>-</span> 
              <span>20</span>
            </div>
            <div>
              <i><font-awesome-icon icon="chevron-left"  /></i>
              <span>Previous</span>
              <button class="btn btn-lg">1</button> 
              <button class="btn btn-inactive">2</button> 
              <button class="btn btn-inactive">3</button> 
              <!-- <v-pagination :length="5"></v-pagination> -->
               <span>Next</span>

              <i><font-awesome-icon icon="chevron-right"  /></i></div>
        </div>
        <div class="modal" v-if="dialog">
          <div class="modal-content">
            <div class="modal-header">
              <p>Profile a Bank Representative</p>
              <i class="icon-cancel" @click="dialog=false"><font-awesome-icon icon="fa-solid fa-times" size="lg"/></i>
            </div>
            
            <div class="complaints-text">
              <input type="file" ref="file" accept=".xlsx, .csv, .xls" class="file-input"/>
            </div>
            <div class="modal-footer">
              <button class="btn-cancel" @click="dialog=false">Cancel</button>
              <button class="btn-submit" @click="createBankRep">Submit</button>
            </div>
           
        </div>
        </div>
        <!-- <modal-layout></modal-layout> -->
    </div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue';
import { useUserStore } from '../stores'
import { makeGetRequest, makePostRequest  } from '@/request';
import { toast } from 'vue3-toastify';
import axios from 'axios';

const swal = inject('$swal')

const dialog = ref(false)
const file = ref(null)
const search = ref('')
const users = ref([])
const preLoader = () => { 
      swal.fire({title: "", 
      customClass: {
          htmlContainer: 'custom-swal-popup'// Add your custom class here
      },
      html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="./images/loading.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});
}
const getBankRep = () => {
    preLoader()
    let request = {
        what: 'GetBankRep',
        params: {

        }
    }
    if(search.value !== '') request.params.search = search.value.toLowerCase()
    makeGetRequest(request)
    .then(res => {
      users.value = res.data.results;
      swal.close()
    })
    .catch(error =>{
      swal.close()
      toast.error(error)
    })
}
const createBankRep = () =>{
  preLoader()
  dialog.value = false
  const data = new FormData()
  data.append('file', file.value.files[0])

  let request = {
    what: 'CreateBankRep',
    data: data
  }
  makePostRequest(request)
  .then(res => {
    if (res.status){
      toast.success(res.msg)
      swal.close()
      getBankRep()
    }
    else{
      toast.error(res.msg)
    }
  })
  .catch(error => {
    swal.close()
    toast.error(error)
  })

}
const handleDelete = (id) => {
        swal({
          title: 'Confirm',
          text: "Are you sure you want to Delete this User?",
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: '#008000',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        })
        .then((result) => {
          if (result.value) {
            preLoader()
            const user = useUserStore()
            const token = user.token
            return axios.delete("/control/bank-representative/" + id + '/', {
              headers:{"Authorization" : `Bearer ${token}`}}
            ).then((res) => {
              console.log(res)
                if (res.data.status == true) {
                    swal({
                          title: 'Successful',
                          text: res.data.msg,
                          icon: 'success',
                      }).then((result) => { 
                      if (result.value) {
                        getBankRep()
                      }
                  })
                } else {
                  toast.error(res.msg)
                }
            }).catch(error => {
              swal.close()
              toast.error(error)
      });
          }
        })
}
onMounted(() => {
  getBankRep()
})
</script>
<script>
export default {
    name: 'UserPage',
}
</script>
<style scoped>
.main-wrapper{
    width: 100%;
}
.main-wrapper button{
    margin: 0;
}
.search-bar{
    display: flex;
    justify-content: space-between;
    width: 97%;
    margin: 17px auto 0 auto;
}
.search-bar input{
    width: 25%;
    outline: none;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 12px 16px;
    height: 42px;
}
.modal{
  align-items: center;
    background: rgba(0,0,0,.486);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.modal-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 89%;
  margin: 0 auto;
}
.modal-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.modal-content{
  background: #fff;
  padding-bottom: 20px;
  border-radius: 4px;
}
.complaints-text{
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  width: clamp(35vw,480px,95vw);
}
.icon-cancel{
  cursor: pointer;
}
nav{
  background: transparent !important;
}
.pagination{
  width: 97%;
  margin: 0 auto;
  display: flex; 
  align-items: center; 
  justify-content:space-between;
}
.pagination div{
  display: flex;
  align-items: center; 
  gap: 10px;
  
}
.pagination div span, .pagination div i{
  font-size: 14px;
}
@media screen and (max-width: 720px){
  .pagination{
    flex-direction: column;
    gap: 15px;
  }
}
</style>