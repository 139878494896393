<template>
    <div class="staff-container">
        <header-layout></header-layout>
         <side-layout></side-layout>
         <div class="status-container">
            <div class="pending-status">
                <h4>Pending</h4>
                <p>34</p>
            </div>
            <div class="resolved-status">
                <h4>Resolved</h4>
                <p>34</p>
            </div>
            <div class="total-status">
                <h4>Total</h4>
                <p>34</p>
            </div>
            <div class="today-status">
                <h4>Complaints Today</h4>
                <p>34</p>
            </div>
         </div>
         <div class="main-container">
             <router-view></router-view>
         </div>
         <footer-layout></footer-layout>
    </div>
</template>
<script>
import HeaderLayout from './Header.vue'
import SideLayout from './Sidebar.vue'
import FooterLayout from './Footer.vue'
export default{
    name: "StaffLayout",
    components:{HeaderLayout, SideLayout, FooterLayout},
    data (){
        return{
            // staff: true
        }
    }
}
</script>
<style>

</style>