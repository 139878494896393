<template>
  <div class="main-wrapper">
    <div class="search-bar">
      <input type="search" name="" id="" placeholder="Search Transaction ID" />
      <button @click="dialog = true">Log Complaint</button>
    </div>
    <v-tabs
      v-model="tab"
     
      align-tabs="end"
    >
    <!-- bg-color="warning" -->
    <!-- style="background: #fdb813 !important" -->
      <v-tab value="one">ATM/POS</v-tab>
      <v-tab value="two">Online Banking</v-tab>
      <v-tab value="three">Blocked Funds</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="one">
        <div class="complaint-table">
          <v-table class="table">
            <thead>
              <tr>
                <th class="text-left">S/N</th>
                <th class="text-left">Transaction ID</th>
                <th class="text-left">Complaint Type</th>
                <th class="text-left">Account Number</th>
                <th class="text-left">Transaction Amount</th>
                <th class="text-left">Transaction Date</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>TRXD33IHE84</td>
                <td>ATM/POS</td>
                <td>0022331211</td>
                <td>2,000.00</td>
                <td>18-06-2023</td>
                <td><span class="status-resolved">Resolved</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td>TRXD33IHE84</td>
                <td>ATM/POS</td>
                <td>0022331211</td>
                <td>2,000.00</td>
                <td>18-06-2023</td>
                <td><span class="status-pending">Pending</span></td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div class="pagination">
          <!-- <v-pagination :length="6"></v-pagination> -->
          <div>
            <span>Page</span>
            <span>1</span>
            <span>of</span>
            <span>10</span> <span>-</span>
            <span>20</span>
          </div>
          <div>
            <i><font-awesome-icon icon="chevron-left" /></i>
            <span>Previous</span>
            <button class="btn btn-lg">1</button>
            <button class="btn btn-inactive">2</button>
            <button class="btn btn-inactive">3</button>
            <!-- <v-pagination :length="5"></v-pagination> -->
            <span>Next</span>

            <i><font-awesome-icon icon="chevron-right" /></i>
          </div>
        </div>
      </v-window-item>
      <v-window-item value="two">
        <div class="complaint-table">
          <v-table class="table">
            <thead>
              <tr>
                <th class="text-left">S/N</th>
                <th class="text-left">Transaction ID</th>
                <th class="text-left">Complaint Type</th>
                <th class="text-left">Account Number</th>
                <th class="text-left">Transaction Amount</th>
                <th class="text-left">Transaction Date</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>TRXD33IHE84</td>
                <td>ATM/POS</td>
                <td>0022331211</td>
                <td>2,000.00</td>
                <td>18-06-2023</td>
                <td><span class="status-resolved">Resolved</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td>TRXD33IHE84</td>
                <td>ATM/POS</td>
                <td>0022331211</td>
                <td>2,000.00</td>
                <td>18-06-2023</td>
                <td><span class="status-pending">Pending</span></td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div class="pagination">
          <!-- <v-pagination :length="6"></v-pagination> -->
          <div>
            <span>Page</span>
            <span>1</span>
            <span>of</span>
            <span>10</span> <span>-</span>
            <span>20</span>
          </div>
          <div>
            <i><font-awesome-icon icon="chevron-left" /></i>
            <span>Previous</span>
            <button class="btn btn-lg">1</button>
            <button class="btn btn-inactive">2</button>
            <button class="btn btn-inactive">3</button>
            <!-- <v-pagination :length="5"></v-pagination> -->
            <span>Next</span>

            <i><font-awesome-icon icon="chevron-right" /></i>
          </div>
        </div>
      </v-window-item>
      <v-window-item value="three">
        <div class="complaint-table">
          <v-table class="table">
            <thead>
              <tr>
                <th class="text-left">S/N</th>
                <th class="text-left">Transaction ID</th>
                <th class="text-left">Complaint Type</th>
                <th class="text-left">Account Number</th>
                <th class="text-left">Transaction Amount</th>
                <th class="text-left">Transaction Date</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>TRXD33IHE84</td>
                <td>ATM/POS</td>
                <td>0022331211</td>
                <td>2,000.00</td>
                <td>18-06-2023</td>
                <td><span class="status-resolved">Resolved</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td>TRXD33IHE84</td>
                <td>ATM/POS</td>
                <td>0022331211</td>
                <td>2,000.00</td>
                <td>18-06-2023</td>
                <td><span class="status-pending">Pending</span></td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div class="pagination">
          <!-- <v-pagination :length="6"></v-pagination> -->
          <div>
            <span>Page</span>
            <span>1</span>
            <span>of</span>
            <span>10</span> <span>-</span>
            <span>20</span>
          </div>
          <div>
            <i><font-awesome-icon icon="chevron-left" /></i>
            <span>Previous</span>
            <button class="btn btn-lg">1</button>
            <button class="btn btn-inactive">2</button>
            <button class="btn btn-inactive">3</button>
            <!-- <v-pagination :length="5"></v-pagination> -->
            <span>Next</span>

            <i><font-awesome-icon icon="chevron-right" /></i>
          </div>
        </div>
      </v-window-item>
    </v-window>
    <!-- <v-window v-model="tab">
     
    </v-window>
    <v-window v-model="tab">
     
    </v-window> -->
    <div class="modal" v-if="dialog">
      <div class="modal-content">
        <div class="modal-header">
          <p>Log Complaint</p>
          <i
            class="icon-cancel"
            @click="
              dialog = false;
              resetForm();
            "
            ><font-awesome-icon icon="fa-solid fa-times" size="lg"
          /></i>
        </div>

        <div class="complaints-text">
          <div class="flex">
            <input
              type="text"
              name=""
              id=""
              class="complaints-input input"
              placeholder="Account Number"
              v-model="formData.account"
            />
            <p style="color: red; font-size: 10px">{{ accountErrorMessage }}</p>
          </div>

          <div class="flex">
            <select
              name=""
              id=""
              class="select-input input"
              v-model="formData.type"
            >
              <option value="" disabled selected hidden>Complaints Type</option>
              <option
                :value="type.id"
                v-for="(type, index) in complaintType"
                :key="index"
              >
                {{ type.name }}
              </option>
            </select>
            <p style="color: red; font-size: 10px">{{ errorMessage }}</p>
          </div>
          <div class="flex">
            <input
              type="text"
              name=""
              id=""
              class="date-input input"
              placeholder="Transaction Date"
              onfocus="(this.type='date')"
              onblur="(this.type='text')"
              v-model="formData.txn_date"
            />
            <p style="color: red; font-size: 10px">{{ dateErrorMessage }}</p>
          </div>
          <div class="flex">
            <input
              type="text"
              name=""
              id=""
              class="complaints-input input"
              placeholder="Amount"
              v-model="formData.amount"
            />
            <p style="color: red; font-size: 10px">{{ amountErrorMessage }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn-cancel"
            @click="
              dialog = false;
              resetForm();
            "
          >
            Cancel
          </button>
          <button class="btn-submit" @click="logComplaints">
            Submit Complaint
          </button>
        </div>
      </div>
    </div>
    <!-- <modal-layout></modal-layout> -->
  </div>
</template>
<script setup>
import { ref, onMounted, inject } from "vue";
import { makeGetRequest, makePostRequest } from "@/request";
import { toast } from "vue3-toastify";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";

const { handleSubmit, validate, resetForm } = useForm();
const { value, errorMessage } = useField("type", yup.string().required());
const { value: amountValue, errorMessage: amountErrorMessage } = useField(
  "amount",
  yup
    .string()
    .required()
    .matches(/^\d+(\.\d{2})?$/, "Invalid money format. Example: 2000.00")
);
const { value: dateValue, errorMessage: dateErrorMessage } = useField(
  "date",
  yup.string().required()
);
const { value: accountNumberValue, errorMessage: accountErrorMessage } =
  useField(
    "account",
    yup
      .string()
      .required()
      .matches(/^\d{10}$/, "Account number must be exactly 10 digits")
  );
const swal = inject("$swal");
const formData = ref({
  account: accountNumberValue,
  txn_date: dateValue,
  amount: amountValue,
  type: value,
});
const dialog = ref(false);
const tab = ref(null);

const complaintType = ref([]);
const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: "custom-swal-popup", // Add your custom class here
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="./images/loading.gif" /></div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
};
const getComplaintsType = () => {
  preLoader();

  var request = {
    what: "GetCustomerComplaintsType",
  };
  makeGetRequest(request)
    .then((res) => {
      if (res.success) {
        complaintType.value = res.data;
        swal.close();
      } else {
        console.log(res.msg);
      }
    })
    .catch((error) => {
      swal.close();
      toast.error(error);
    });
};
const logComplaints = handleSubmit(async () => {
  const isValid = await validate();
  if (isValid) {
    preLoader();
    dialog.value = false;
    var request = {
      what: "LogComplaints",
      data: formData.value,
    };
    makePostRequest(request)
      .then((res) => {
        if (res.status == true) {
          toast.success(res.msg);
          resetForm();
          // getComplaintsType()
          swal.close();
        } else {
          toast.error(res.msg);
          swal.close();
        }
      })
      .catch((error) => {
        swal.close();
        toast.error(error);
      });
  }
});
onMounted(() => {
  getComplaintsType();
});
</script>
<script>
export default {
  name: "StaffComplaint",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style scoped>
.main-wrapper {
  width: 100%;
}
.main-wrapper button {
  margin: 0;
}
.search-bar {
  display: flex;
  justify-content: space-between;
  width: 97%;
  margin: 17px auto 0 auto;
}
.search-bar input {
  width: 25%;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 12px 16px;
  height: 42px;
}
.modal {
  align-items: center;
  background: rgba(0, 0, 0, 0.486);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 89%;
  margin: 0 auto;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.modal-content {
  background: #fff;
  padding-bottom: 20px;
  border-radius: 4px;
}
.complaints-text {
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  width: clamp(35vw, 480px, 95vw);
}
.icon-cancel {
  cursor: pointer;
}
nav {
  background: transparent !important;
}
.pagination {
  width: 97%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pagination div span,
.pagination div i {
  font-size: 14px;
}
.flex {
  display: flex;
  flex-direction: column;
}
.tab-button {
  background-color: #fff !important;
  color: #000 !important;
}
.tab-button:hover {
  color: #fdb813 !important;
  background-color: #fff !important;
}
@media screen and (max-width: 720px) {
  .pagination {
    flex-direction: column;
    gap: 15px;
  }
}
</style>
