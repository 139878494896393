/*
 * Main and demo navigation arrays
 */

export default {
    'main': [
      {
        name: 'Users',
        to: '/users',
        icon: 'fa-user',
        role: 'System Administrator'
      },
      {
        name: 'Complaints Type',
        to: '/types',
        icon: 'fa-tag',
        role: 'System Administrator'
      },
      {
        name: 'Log Complaints',
        to: '/log-complaint',
        icon: 'fa-message',
        role: 'Customer'
      },
      {
        name: 'Complaints',
        to: '/complaints',
        icon: 'fa-message',
        role: 'Bank Representative'
      },
      {
        name: 'Track Complaints',
        to: '/track-complaint',
        icon: 'fa-search',
        role: ['Customer', 'Bank Representative']
      },
      {
        name: 'Contact',
        to: '/contact',
        icon: 'fa-phone',
        role: 'Customer'
      }
      
    ]
  }
  